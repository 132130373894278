@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.012em;
  color: #37352f;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: Roboto Mono, Monaco, Consolas, 'Courier New', monospace;
}

.monaco-editor {
  position: absolute !important;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer components {
  .shadow-around {
    position: relative;
    background: white;
  }

  .shadow-around::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    border-radius: 0.5rem;
    box-shadow: 0 3px 6px 0 rgba(15, 15, 15, 0.1), 0 9px 24px 0 rgba(15, 15, 15, 0.2);
    overflow: visible;
  }
}

.modified-in-monaco-diff-editor {
  .margin {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .intercom-app div[class^='intercom-'] {
    top: 150px;
  }

  .intercom-lightweight-app-launcher.intercom-launcher {
    top: 150px;
  }
}
.chatMessageMarkdown {
  white-space: normal;
  width: 100%;
}

.chatMessageMarkdown h1 {
  font-size: 24px; /* Font size for h1 */
  margin-top: 14px; /* Space above the heading */
  margin-bottom: 14px; /* Space below the heading */
}

.chatMessageMarkdown h2 {
  font-size: 22px; /* Font size for h2 */
  margin-top: 12px; /* Space above the heading */
  margin-bottom: 12px; /* Space below the heading */
}

.chatMessageMarkdown h3 {
  font-size: 18px; /* Font size for h3 */
  margin-top: 10px; /* Space above the heading */
  margin-bottom: 10px; /* Space below the heading */
}

.chatMessageMarkdown h4 {
  font-size: 16px; /* Font size for h3 */
  margin-top: 8px; /* Space above the heading */
  margin-bottom: 8px; /* Space below the heading */
}

.chatMessageMarkdown h5 {
  font-size: 14px; /* Font size for h3 */
  margin-top: 6px; /* Space above the heading */
  margin-bottom: 6px; /* Space below the heading */
}

.chatMessageMarkdown h5 {
  font-size: 14px; /* Font size for h3 */
  margin-top: 4px; /* Space above the heading */
  margin-bottom: 4px; /* Space below the heading */
}

.chatMessageMarkdown p {
  font-size: 14px; /* Font size for paragraphs */
  margin-top: 4px;
  margin-bottom: 4px;
}

.chatMessageMarkdown a {
  color: #1a0dab; /* Color for hyperlinks */
  text-decoration: underline; /* Underline hyperlinks */
}

.chatMessageMarkdown ol {
  list-style-type: decimal; /* Adds numbers to ordered lists */
  padding-left: 20px; /* Indentation for list items */
}

.chatMessageMarkdown ul {
  list-style-type: disc; /* Adds bullet points to list items */
  padding-left: 20px; /* Ensures indentation for list items */
}

.chatMessageMarkdown ul ul {
  list-style-type: circle; /* Different bullet style for nested items */
}

.chatMessageMarkdown ul ul ul {
  list-style-type: square; /* Another style for deeper nested items */
}

.chatMessageMarkdown li {
  font-size: 14px;
  margin-top: 4px; /* Spacing between list items */
  margin-bottom: 4px; /* Spacing between list items */
}

.chatMessageMarkdown blockquote {
  border-left: 4px solid #ccc; /* Border color for blockquotes */
  padding-left: 16px; /* Padding inside blockquotes */
  font-style: italic; /* Font style for blockquotes */
  color: black;
}

.chatMessageMarkdown code {
  background-color: #f4f4f4; /* Background color for inline code */
  padding: 2px 4px; /* Padding for inline code */
  font-family: monospace; /* Font family for inline code */
  color: black;
}

.chatMessageMarkdown pre {
  background-color: #f4f4f4; /* Background color for code blocks */
  padding: 10px; /* Padding for code blocks */
  overflow-x: auto; /* Horizontal scrolling for long code */
  font-family: monospace; /* Font family for code blocks */
  color: black;
}

.chatMessageMarkdown table {
  width: 100%; /* Full-width tables */
  border-collapse: collapse; /* Remove space between borders */
  margin-bottom: 20px; /* Margin below the table */
}

.chatMessageMarkdown th .chatMessageMarkdown td {
  border: 1px solid #ddd; /* Border color for table cells */
  padding: 8px; /* Padding inside table cells */
  text-align: left; /* Text alignment in cells */
}

.chatMessageMarkdown th {
  background-color: #f4f4f4; /* Background color for header cells */
  color: black;
}

.chatMessageMarkdown tr:nth-child(even) {
  background-color: #f9f9f9; /* Zebra striping for rows */
  color: black;
}

.chatMessageMarkdown tr:hover {
  background-color: #f1f1f1; /* Hover effect for rows */
  color: black;
}

.breakLongWords {
  overflow-wrap: anywhere;
  word-break: normal;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltip-text {
  visibility: hidden;
  width: 150px;
  max-width: 150px;
  word-break: break-word;
  white-space: normal;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  /* Combined transition for opacity and visibility */
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  transform: translateY();
  position: fixed;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltip-text {
  visibility: visible;
  transition: opacity 0.5s ease-in-out 1s, transform 0.5s ease-in-out 1s;
  opacity: 1;
}

.tooltip.hide .tooltip-text {
  visibility: hidden;
  opacity: 0;
}

/* Overrides for splitter to make child scrollable.*/
.react-split .full-content {
  overflow: hidden;
  overflow-y: scroll !important;
}

/* vscode ui controls */

.statusbar-item .right {
  visibility: none;
}
